import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller';
import Details from './Details';
import { execute, makePromise } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { photoRoot, thumbsRoot, link, cache } from './index'

// const uri = 'http://localhost:8080/v1/graphql';
// const link = new HttpLink({ uri });


// const { createApolloFetch } = require('apollo-fetch');

// const fetch = createApolloFetch({
//   uri: 'http://localhost:8080/v1/graphql',
// });

const SOME_PICS = gql`
  query Pictures($campyear: String, $campsession: String, $credit: String, $offset: Int) {
    pictures(where: {campyear: {_eq: $campyear}, campsession: {_eq: $campsession}, credit: {_eq: $credit}}, limit: 12, offset: $offset, order_by: {id: asc}) {
      id
      filename
      __typename
    }
  }
`;


type Picture = {
  id: string;
  filename: string;
};

type CacheData = {
  pictures: Picture[];
};


// const photoRoot = 'http://winnarainbowpictures.foodnotblogs.com/pictures/'


const Photos: React.FC = () => {

  const [photos, setPhotos] = useState([])

  function loadItems(page : number) {

    console.log("loadItems called");

    var variables = {}

    const varkeys = ['campyear', 'campsession', 'credit']

    const offset = page * 12

    varkeys.forEach(element => {
      if (searchQuery.has(element)) {
        variables[element] = searchQuery.get(element)
      }});

    variables['offset'] = offset

    console.log(variables);


    const operation = {
      query: SOME_PICS,
      variables: variables
    };

    makePromise(execute(link, operation))
      .then(data => {
        console.log(`received data ${JSON.stringify(data.data, null, 2)}`);

        if (data.data !== undefined && data.data !== null) {

          setPhotos(photos.concat(data.data.pictures))

          cache.writeData({data: { pictures: data.data.pictures }})

          // TODO: how to append data = https://www.apollographql.com/docs/react/data/local-state/#writequery-and-readquery

          // cache.writeData({data: { pictures: pictures.concat(data.data.pictures) }})

          const query = gql`
            query GetCachedPhotos {
              pictures @client {
                id
                filename
              }
            }
          `;

          const previous = cache.readQuery({ query });
          console.log('previous')
          console.log(previous)
          const newPhotos = data.data.pictures

          // const data_new: { [key: string]: string } = {
          //   pictures: newPhotos.concat(previous)
          // };

          const data_new: CacheData = {
            pictures: newPhotos.concat(previous)
          };

          // cache.writeQuery({ query, <DataCa>data_new });
          // cache.writeData({ data_new })



          // TODO: try a different cache systm like Mobx?


          
        }
        // console.log(photos)
      })
      .catch(error => console.log(`received error ${error}`))

    return true;

  };


  const searchQuery = new URLSearchParams(useLocation().search);

  const loader = <div className="loader">Loading ...</div>;

  return (
    <div>
      <p className="heading">Photos</p>
      
      <div className="gallery-image">

      <InfiniteScroll
                pageStart={0}
                loadMore={loadItems}
                hasMore = {true}
                // hasMore={this.state.hasMoreItems}
                loader={loader}>

            {photos.map(picture => (
              <div className="img-box">

                {/* {picture["filename"]} */}

                <Link to={`/photos/${picture['id']}`}>
                  <img src={`${thumbsRoot}${picture['filename']}`} alt={picture['id']} />
                </Link>
                
              </div>
            ))}

            </InfiniteScroll>

      </div>
{/*   
      <Route path="/photos/:id" component={Details} /> */}

      <div className="footer-nav">
        Home | Young and in the Way | History | Other | Log Out
      </div>
    
    </div>
  );
}

export default Photos;
