import React, { useEffect } from 'react';
import './App.css';

const App: React.FC = () => {

  useEffect(() => {
    document.title = "New CWR Pics"
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Home page
        </p>
      </header>
    </div>
  );
}

export default App;
