import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';


const PICTURE_TAGS = gql`
  query PictureTags($id: bigint) {
    keyphrases(where: {picture_id: {_eq: $id}}) {
      phrase
      id
    }
  }
`;


const Tags: React.FC<{picture_id: string}> = ({ picture_id }) => {

  const { loading, error, data } = useQuery(
    PICTURE_TAGS, { variables: {id: parseInt(picture_id)}}
  );

  if (loading) return <p>Loading...</p>;
  // if (error) return `Error! ${error.message}`;

  return (
    <div >Tags: 

      {data.keyphrases.map(phrase => (
        <div>
          {phrase.phrase}, 
        </div>
      ))}

    </div>
  );
}

export default Tags;
