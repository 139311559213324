import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import Photos from './Photos';
import Page from './Page';
import ApolloClient from "apollo-client";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';


export const photoRoot = 'http://winnarainbowpictures.foodnotblogs.com/pictures/'

export const thumbsRoot = 'http://winnarainbowpictures.foodnotblogs.com/thumbs/'

const httpLink = new HttpLink({
  // uri: 'http://localhost:8080/v1/graphql'
  uri: 'http://cwrpix.herokuapp.com/v1/graphql'
});

const wsLink = new WebSocketLink({
  // uri: 'ws://localhost:8080/v1/graphql',
  uri: 'ws://cwrpix.herokuapp.com/v1/graphql',
  options: {
    reconnect: true
  }
});

export const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const cache = new InMemoryCache()

export const client = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    link,
  ]),
});

cache.writeData({
  data: {
    pictures: [],
    currentPhoto: 0,
    networkStatus: {
      __typename: 'NetworkStatus',
      isConnected: false,
    },
  },
});


const routing = (

  <ApolloProvider client={client}>
    <Router>

      <Page />

    </Router>

  </ApolloProvider>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
