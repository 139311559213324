import React, {useEffect} from 'react';
import { RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import Tags from './Tags'
import { Link } from 'react-router-dom'
import { photoRoot, link, cache } from './index'

// const photoRoot = 'http://winnarainbowpictures.foodnotblogs.com/pictures/'

const PIC_SUBSCRIPTION = gql`
  subscription PictureSubscription($id: bigint!) {
    pictures_by_pk(id: $id) {
      id
      filename
      campyear
      campsession
      credit
      views
      keyphrases {
        id
        phrase
      }
    }
  }
`;

const INCREMENT_VIEWS = gql`
  mutation IncrementViews($id: bigint) {
    update_pictures(where: {id: {_eq: $id}}, _set: {}, _inc: {views: 1}) {
      returning {
        views
      }
    }
  }
`;


type DetailsProps = RouteComponentProps<{id: string}>;

const Details: React.FC<DetailsProps> = ({match}) => {

  const [incViews] = useMutation(INCREMENT_VIEWS, {
    variables: { id: parseInt(match.params.id)},
  } );

  useEffect(() => {  incViews()  }, []);

  const { data, loading } = useSubscription(
    PIC_SUBSCRIPTION,
    { variables: { id: parseInt(match.params.id) } }
  );

  if (loading) return <p>Loading...</p>;

  const picture = data.pictures_by_pk

  cache.writeData({data: { currentPhoto: picture }})

  return (
    <div>
      <p className="heading">Picture 3 of <Link to="/list?campyear=2008&campsession=D">241</Link></p>
      <div className="detail-image">
        {/* <a href=left>arrow left</a> ...  */}
        <img src={`${photoRoot}${picture.filename}`} alt={picture.id} />
        {/* ... <a href=right>arrow right</a> */}
      </div>
    
      <div className="detail-nav">
        <p>
          {(picture.campyear == null && picture.campsession == null) ? (
            <div>date unknown</div>
          ) : (
            <div>{picture.campyear} {picture.campsession}</div>
          )}       
        </p>
        <p>{picture.credit}</p>
        <p>{picture.views} views</p>
        <Tags picture_id={picture.id} />
      </div>
    </div>
    
  );
}

export default Details;
