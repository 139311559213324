import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom'

import { Route, BrowserRouter as Router } from 'react-router-dom'

import App from './App';
import Photos from './Photos';
import Details from './Details';

// const initialState = { clicksCount: 0 }
// type State = Readonly<typeof initialState>


const Page: React.FC = () => {

  const [firstName, setFirstName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPhoto, setCurrentPhoto] = useState("");


  // constructor(props) {
  //   super(props)

  // }

  // readonly state: State = initialState

  useEffect(() => {
    document.title = "New CWR Pics P"
  }, []);

  return (
    <div className="Page">
      page
      <div>
        currentPhoto: {currentPhoto}
      </div>

      <ul>
        <li>
          <Link to="/">home</Link>
        </li>
        <li>
          <Link to="/list">list</Link>
        </li>
        {/* <li>
          <Link to="/list?campyear=2008&campsession=D">search 2008 D</Link>
        </li> */}
      </ul>

      <div>
        <Route exact path="/" component={App} />
        <Route path="/list" component={Photos} />
        <Route path="/photos/:id" component={Details} />
      </div>

    </div>
  );
}

export default Page;
